import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Anchor } from 'grommet';
 
import SignOutButton from '../SignOut';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = () => (
  <Nav direction="row" background="brand" pad="medium">
    <Anchor href={ROUTES.HOME} label="Home" hoverIndicator />
    <Anchor href={ROUTES.USERS} label="Users" hoverIndicator />
    <Anchor href={ROUTES.CONNECTS} label="Connects" hoverIndicator />
    <Anchor href={ROUTES.REFERRALS} label="Referrals" hoverIndicator />
    <Anchor href={ROUTES.PROMOTEDUSERS} label="Promoted Users" hoverIndicator />
    <Anchor href={ROUTES.DISPUTES} label="Disputes" hoverIndicator />
    <Anchor href={ROUTES.ERRORS} label="Errors" hoverIndicator />
    <Anchor href={ROUTES.REVIEWS} label="Reviews" hoverIndicator />
    <Anchor href={ROUTES.APPFLAGS} label="App Flags" hoverIndicator />
    <Anchor href={ROUTES.GENRES} label="Genres" hoverIndicator />
    <Anchor href={ROUTES.LEGAL} label="Legal" hoverIndicator />
    <Anchor>
      <SignOutButton />
    </Anchor>
  </Nav>
);
 
const NavigationNonAuth = () => (
  <Nav direction="row" background="brand" pad="medium">
    <Anchor href={ROUTES.LANDING} label="Sign In" hoverIndicator />
  </Nav>
);
 
export default Navigation;