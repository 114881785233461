import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Box, DataTable, Text } from 'grommet';
import { compose } from 'recompose';
import * as moment from 'moment';
 
import { withFirebase } from '../Firebase';

const tableSort = ({ users, property, direction }) => {
  const keys = property.split('.');
  const getValue = (obj) => {
    return keys.reduce((o, k) => o[k], obj);
  };
  return users.sort(function(a, b) {
    const left = getValue(a);
    const right = getValue(b);
    if (!left) return direction !== 'asc';
    if (!right) return direction === 'asc';
    return direction === 'asc' ? left > right : left < right
  });
};

const borderConfig = {
  header: {
    color: "border",
    side: "both",
    size: "small"
  },
  body: {
    color: "border",
    side: "both",
    size: "small"
  }
}; 

class UsersPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      users: [],
      reports: [],
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    const prepareReports = (users, reports) => {
      return Object.keys(reports).map(userId => {
        return {
          reporter: users.filter((x) => x.uid === reports[userId].reporter)[0],
          report: reports[userId],
          ...users.filter((x) => x.uid === userId)[0],
        };
      });
    };
 
    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      this.props.firebase.profiles().on('value', snapshot => {
        const profilesObject = snapshot.val();

        const usersList = Object.keys(usersObject).map(key => ({
          user: usersObject[key] || {},
          profile: profilesObject[key] || {},
          uid: key,
        }));
   
        if (this.state.reports && this.state.reports.length > 0) {
          this.setState({
            users: usersList,
            reports: prepareReports(usersList, snapshot.val()) || [],
            loading: false,
          });
        } else {
          this.setState({
            users: usersList,
            loading: false,
          });
        }
      });
    });

    this.props.firebase.db.ref('reported_users').on('value', snapshot => {
      if (this.state.users.length > 0) {
        this.setState({
          reports: prepareReports(this.state.users, snapshot.val()) || []
        });
      } else {
        this.setState({
          reports: snapshot.val() || []
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
    this.props.firebase.profiles().off();
    this.props.firebase.db.ref('reported_users').off();
  }
 
  render() {
    const { users, reports, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading ...</div>}

        <Tabs>
          <Tab title="All Users">
            <Box pad="medium">
              <h1>All Users ({users.length})</h1>
              <UserList users={users} onClickUid={(uid) => this.props.history.push('/users/' + uid)} />
            </Box>
          </Tab>
          <Tab title="Reported Users">
            <Box pad="medium">
              <h1>Reported Users ({reports.length})</h1>
              <ReportedUserList reports={reports} onClickUid={(uid) => this.props.history.push('/users/' + uid)} />
            </Box>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const UserList = ({ users, onClickUid }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="uid"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.uid);
    }}
    onSort={(obj) => tableSort({ users, ...obj }) }
    sort={
      {
        direction: 'desc',
        property: 'profile.created_at'
      }
    }
    columns={[
      {
        property: 'profile.alias',
        header: <Text>Alias</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'user.last_name',
        header: <Text>Full Name</Text>,
        search: true,
        sortable: true,
        render: datum => (
          <Text>{datum.user.first_name} {datum.user.last_name}</Text>
        ),
      },
      {
        property: 'user.email',
        header: <Text>Email</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'user.phone',
        header: <Text>Phone</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'uid',
        header: <Text>ID</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'user.stripe_account_id',
        header: <Text>Submerchant ID</Text>,
        search: true,
        sortable: true,
      },
      {
        header: <Text>Admin</Text>,
        property: 'user.is_admin',
        render: datum => (
          <Text>{datum.user.is_admin === true ? "✅" : "❌"}</Text>
        ),
        sortable: true,
      },
      {
        header: <Text>Industry Exec</Text>,
        property: 'profile.is_watcher',
        render: datum => (
          <Text>{datum.user.is_watcher === true ? "✅" : "❌"}</Text>
        ),
        sortable: true,
      },
      {
        header: <Text>Last Opened</Text>,
        property: 'user.last_opened_at',
        render: datum => {
          const timestamp = datum.user.last_opened_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        header: <Text>Last Active</Text>,
        property: 'profile.last_active',
        render: datum => {
          const timestamp = datum.profile.last_active;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        header: <Text>Signed Up</Text>,
        property: 'profile.created_at',
        render: datum => {
          const timestamp = datum.profile.created_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        header: <Text>Submerchant Creation</Text>,
        property: 'user.verified_at',
        render: datum => {
          const timestamp = datum.user.verified_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
    ]}
    data={users}
  />
);

const ReportedUserList = ({ reports, onClickUid }) => (
  <DataTable
    sortable={true}
    step={30}
    primaryKey="uid"
    border={borderConfig}
    onClickRow={({ datum }) => {
      onClickUid(datum.uid);
    }}
    onSort={(obj) => tableSort({ reports, ...obj }) }
    sort={
      {
        direction: 'desc',
        property: 'report.reported_at'
      }
    }
    columns={[
      {
        property: 'profile.alias',
        header: <Text>Alias</Text>,
        search: true,
        sortable: true,
      },
      {
        property: 'user.last_name',
        header: <Text>Full Name</Text>,
        search: true,
        sortable: true,
        render: datum => (
          <Text>{datum.user.first_name} {datum.user.last_name}</Text>
        ),
      },
      {
        property: 'uid',
        header: <Text>ID</Text>,
        search: true,
        sortable: false,
      },
      {
        property: 'reporter.alias',
        header: <Text>Reported By</Text>,
        search: true,
        sortable: false,
        render: datum => (
          <Text>{datum.reporter.first_name} {datum.reporter.last_name} ({datum.reporter.alias})</Text>
        ),
      },
      {
        property: 'report.report_reason',
        header: <Text>Reason</Text>,
        search: true,
        sortable: false,
      },
      {
        header: <Text>Reported At</Text>,
        property: 'report.reported_at',
        render: datum => {
          const timestamp = datum.report.reported_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
      {
        header: <Text>Industry Exec</Text>,
        property: 'profile.is_watcher',
        render: datum => (
          <Text>{datum.user.is_watcher === true ? "✅" : "❌"}</Text>
        ),
        sortable: true,
      },
      {
        header: <Text>Last Opened</Text>,
        property: 'user.last_opened_at',
        render: datum => {
          const timestamp = datum.user.last_opened_at;
          return (
            <Text>{timestamp ? moment(timestamp).fromNow() : 'never'}</Text>
          )
        },
        sortable: true,
      },
    ]}
    data={reports}
  />
);
 
export default compose(
  withRouter,
  withFirebase,
)(UsersPage);