import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Grommet } from 'grommet';
 
import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import UsersPage from '../Users';
import UserPage from '../User';
import GenresPage from '../Genres';
import ConnectPage from '../Connect';
import ChatPage from '../Chat';
import ConnectsPage from '../Connects';
import DisputesPage from '../Disputes';
import ErrorsPage from '../Errors';
import ReviewsPage from '../Reviews';
import AppFlagsPage from '../AppFlags';
import LegalPage from '../Legal';
import ReferralsPage from '../Referrals';
import PromotedUsersPage from '../PromotedUsers';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    input: {
      font: {
        size: '12px',
        height: '14px',
        weight: 400
      }
    },
    colors: {
      "brand": {
        "dark": "#0660DB",
        "light": "#0660DB"
      },
      "background": {
        "dark": "#EFF5FB",
        "light": "#EFF5FB"
      },
      "background-back": {
        "dark": "#EFF5FB",
        "light": "#EFF5FB"
      },
      "background-front": {
        "dark": "#EFF5FB",
        "light": "#EFF5FB"
      },
      "background-contrast": {
        "dark": "#EFF5FB11",
        "light": "#EFF5FB11"
      },
      "text": {
        "dark": "#003366",
        "light": "#003366"
      },
      "text-strong": {
        "dark": "#003366",
        "light": "#003366"
      },
      "text-weak": {
        "dark": "#7F99B2",
        "light": "#7F99B2"
      },
      "text-xweak": {
        "dark": "#7F99B2",
        "light": "#7F99B2"
      },
      "border": {
        "dark": "#448AFF",
        "light": "#448AFF"
      },
      "control": "#7F99B2",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning"
    }
  },
};
 
const App = () => {
  return (
    <Router>
      <Grommet theme={theme}>
        <Navigation />
   
        <hr />
   
        <Route exact path={ROUTES.LANDING} component={SignInPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.USERS} component={UsersPage} />
        <Route path={ROUTES.USER} component={UserPage} />
        <Route exact path={ROUTES.CONNECTS} component={ConnectsPage} />
        <Route path={ROUTES.CONNECT} component={ConnectPage} />
        <Route exact path={ROUTES.REFERRALS} component={ReferralsPage} />
        <Route exact path={ROUTES.DISPUTES} component={DisputesPage} />
        <Route exact path={ROUTES.ERRORS} component={ErrorsPage} />
        <Route exact path={ROUTES.REVIEWS} component={ReviewsPage} />
        <Route path={ROUTES.GENRES} component={GenresPage} />
        <Route path={ROUTES.APPFLAGS} component={AppFlagsPage} />
        <Route path={ROUTES.LEGAL} component={LegalPage} />
        <Route path={ROUTES.PROMOTEDUSERS} component={PromotedUsersPage} />
      </Grommet>
    </Router>
  );
};
 
export default withAuthentication(App);