import React, { Component } from 'react';
import { Box, DataTable, Text, Button, Form, FormField, TextInput } from 'grommet';
 
import { withFirebase } from '../Firebase';
 
class GenresPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      loading: false,
      genres: [],
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.db.ref('genres').on('value', snapshot => { 
      this.setState({
        genres: Object.values(snapshot.val() || {}),
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref('genres').off();
  }

  onAdd(name) {
    if (window.confirm("Are you sure you want to add the genre \"" + name + "?\"")) {
      this.props.firebase.db
        .ref("/genres")
        .push({name: name})
        .then(ref => {
          ref
            .child("id")
            .set(ref.key)
             .then(() => {
               this.setState({
                 genres: [{name: name, id: ref.key}].concat(this.state.genres)
               })
             })
             .catch(error => {
               console.log(error);
             });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  onEdit(genre) {
    const newName = window.prompt("What would you like to change the name to?");
    if (newName && window.confirm("Are you sure you want to change genre \"" + genre.name + "\" to \"" + newName + "?\"")) {
      let id = genre.id;
      this.props.firebase.db
        .ref("/genres/" + id)
        .set({id: id, name: genre.name})
        .then(() => {
          const index = this.state.genres.findIndex((a) => a.id == genre.id);
          var genres = this.state.genres;
          genres[index] = genre;
          this.setState({
            genres: genres
          })
         })
        .catch(error => {
          console.log(error);
        });
    }
  }

  onRemove(genre) {
    if (window.confirm("Are you sure you want to delete genre \"" + genre.name+ "?\"")) {
      this.props.firebase.db
        .ref("/genres/" + genre.id)
        .remove()
        .then(() => {
          const index = this.state.genres.findIndex((a) => a.id == genre.id);
          var genres = this.state.genres;
          genres.splice(index, 1);
          this.setState({
            genres: genres
          })
         })
        .catch(error => {
          console.log(error);
        });
    }
  }
 
  render() {
    const { genres, loading } = this.state;

    return (
      <div> 
        {loading && <div>Loading ...</div>}
 
        <Box pad="medium" gap="medium" wrap={true} fill={true} direction="row-responsive">
          <Box>
            <GenreList genres={genres} onEdit={this.onEdit} onRemove={this.onRemove} />
          </Box>
          <Box pad="small" width="small">
            <GenreAdd onAdd={this.onAdd} />
          </Box>
        </Box>
      </div>
    );
  }
}

const GenreAdd = ({ onAdd }) => {
  const [value, setValue] = React.useState({});

  return (
    <Form
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onReset={() => setValue({})}
      onSubmit={({ value }) => onAdd(value.name)}
    >
      <FormField name="name" htmlfor="name" label="New Genre">
        <TextInput id="name" name="name" />
      </FormField>
      <Button type="submit" primary label="Add" />
    </Form>
  );
}

const GenreList = ({ genres, onEdit, onRemove }) => (
  <DataTable
    step={30}
    primaryKey="uid"
    border={
      {
        color: "border",
        side: "both",
        size: "small"
      }
    }
    sort={
      {
        direction: 'asc',
        property: 'name'
      }
    }
    columns={[
      {
        property: 'id',
        header: <Text>ID</Text>,
        search: true,
      },
      {
        property: 'name',
        header: <Text>Name</Text>,
        search: true,
      },
      {
        header: <Text>Actions</Text>,
        search: false,
        render: datum => (
          <Box gap="xsmall">
            <Button primary label="Edit" color="neutral-3" onClick={() => onEdit(datum)} />
            <Button primary label="Delete" color="status-critical" onClick={() => onRemove(datum)} />
          </Box>
        ),
      },
    ]}
    data={genres}
  />
);
 
export default withFirebase(GenresPage);