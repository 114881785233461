export const LANDING = '/';
export const HOME = '/home';
export const USERS = '/users';
export const USER = '/users/:uid';
export const CONNECTS = '/connects';
export const CONNECT = '/connects/:uid';
export const GENRES = '/genres';
export const DISPUTES = '/disputes';
export const ERRORS = '/errors';
export const REVIEWS = '/reviews';
export const REFERRALS = '/referrals';
export const APPFLAGS = '/appFlags';
export const LEGAL = '/legal';
export const PROMOTEDUSERS = '/promotedUsers';
