import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebase from 'firebase';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: ROUTES.HOME,
  signInOptions: [
    firebase.auth.PhoneAuthProvider.PROVIDER_ID
  ]
};
 
const SignIn = ({ firebase }) => (
  <div>
    <p>Please sign-in:</p>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth}/>
  </div>
);
 
export default withFirebase(SignIn);