import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();

    var that = this;

    app.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken()
          .then(token => {
            that.token = user.token;
          });
      } else {
        that.token = null;
      }
    });
  }

  // *** Auth API ***
 
  doSignOut = () => this.auth.signOut();

  // *** User API ***
 
  user = uid => this.db.ref(`users/${uid}`);
 
  users = () => this.db.ref('users');

  // *** Profile API ***
 
  profile = uid => this.db.ref(`profiles/${uid}`);
 
  profiles = () => this.db.ref('profiles');

  watchers = () => this.db.ref('profiles').orderByChild('is_watcher').equalTo(true);

  setAdmin = (uid, val) => this.user(uid).child('is_admin').set(val);
  setWatcher = (uid, val) => this.profile(uid).child('is_watcher').set(val);
  setManager = (uid, val) => this.profile(uid).child('is_manager').set(val);
  setLevelOverride = (uid, val) => this.profile(uid).child('level_override').set(val == "disabled" ? null : val);
  deleteAccount = (user, profile) => {
    if (window.confirm("This will delete the user from the database. This action is not reversible. Do you want to proceed?")) {
      this.profile(user.id).set(null)
        .then(() => this.user(user.id).set(null))
        .then(() => this.db.ref(`index/display_name/${user.display_name}`).set(null))
        .then(() => this.db.ref(`index/email/${user.email}`).set(null))
        .then(() => this.db.ref(`index/phone/${user.phone}`).set(null))
        .then(() => this.db.ref(`notification_tokens/${user.id}`).set(null))
    }
  };
  returnToOnboarding = uid => this.user(uid).child('location').set(null);
}
 
export default Firebase;
